import 'react-hot-loader/patch'
import React from "react";
import ReactDOM from "react-dom";
import {initSentrySession} from "../../service/SentryService";
import { StyleContext, styleContextRawData } from '../../customHooks/useStyleContext';
import { CustomerProvider } from '../../customHooks/useCustomer';
import MenuContainer from '../components/MenuContainer/MenuContainer';

const rootElement = document.getElementById("customer-menu");
const styles = styleContextRawData();

initSentrySession(styles?.env ?? 'dev');

const renderApp = () => {
    if (!rootElement) {
        return;
    }
    
    ReactDOM.render(
        <StyleContext.Provider value={styles}>
            <CustomerProvider>
                <MenuContainer />
            </CustomerProvider>
        </StyleContext.Provider>,
        rootElement
    );
};

renderApp();